<template>
  <div class="p-3">
    <b-row>
      <b-col cols="12" v-if="!sosyalAg.length">
        <b-alert variant="warning" show class="text-center">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Henüz Kayıt Bulunmamaktadır.</p>
        </b-alert>
      </b-col>
      <b-col cols="12" v-else>
        <draggable v-model="sosyalAg" tag="ul" class="list-group" handle=".draggable-sort-item" @end="handlerSiraChange">
          <SosyalItem
            v-for="(item, index) in sosyalAg"
            :key="index"
            :index="index"
            :item="item"
            :sosyal="sosyal"
            @show="$emit('show', $event)"
          />
        </draggable>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" lg="6">
        <div class="d-flex">
          <b-button squared block size="lg" variant="primary" class="mr-2" @click="onSubmit">
            <i class="fad fa-save pr-2"></i>
            YENİ EKLE
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import draggable from 'vuedraggable';
import SosyalItem from './component/SosyalItem.vue';
import { useToast } from 'vue-toastification/composition';
import { computed, defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  components: {
    SosyalItem,
    draggable,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.sosyalAg = ref([]);
    expo.sosyal = ref([
      'Facebook',
      'Instagram',
      'Twitter',
      'Google plus',
      'Behance',
      'Bitbucket',
      'Codepen',
      'Delicious',
      'Deviantart',
      'Dribbble',
      'Flickr',
      'Foursquare',
      'Github',
      'Jsfiddle',
      'Linkedin',
      'Medium',
      'Pinterest',
      'Reddit',
      'Stumbleupon',
      'Tumblr',
      'Twitch',
      'Vimeo',
      'Vine',
      'Vk',
      'Weibo',
    ]);

    expo.form = ref({
      baslik: 'Faceboook',
      url: 'https://example.com',
      icon: null,
      statu: true,
    });

    const handlerGetData = async () => {
      context.emit('show', true);
      await store.dispatch('sosyalMedyaListele');
      expo.sosyalAg.value = store.getters.getSosyalMedyaListele;
      context.emit('show', false);
    };

    handlerGetData();

    expo.handlerSiraChange = () => {
      store
        .dispatch('sosyalMedyaSiraGuncelle', { sosyal: expo.sosyalAg.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sıra güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('sosyalMedyaEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Ekleme Başarılı.', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
